import React, { useContext, useEffect, useState } from 'react';
//import { Grid, Card, CardContent, TextField, Typography, Autocomplete, Chip, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, IconButton, Tooltip } from '@mui/material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tab, Tabs, Card, CardContent, TextField, Typography, Autocomplete, Chip, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, IconButton, Tooltip } from '@mui/material';

import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
} from "@progress/kendo-react-charts";
import "hammerjs";

import '@progress/kendo-theme-default/dist/all.css';
import { bajarPdf, getUsuarios, getFicheros, getAnalisis, getClientes, getConfPlantaClientePorClienteOferta, getOfertas, getParametros, getParametrosAnalisisPlanta, getTareas, getValorParametros, bajarPdfNoFQ, bajarPdfDashBoard } from '../api';
import { AuthContext } from '../context/AuthContext';
import { useDiagrama } from '../helpers/generarDiagrama';
import ReactFlow, { Background } from 'react-flow-renderer';
import { 
    useNodesState,
    useEdgesState
  } from '@xyflow/react';
import { DashboardContext } from '../context/DashboardContext';
import TimelineIcon from '@mui/icons-material/Timeline';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import RemoveIcon from '@mui/icons-material/Remove';
import WarningIcon from '@mui/icons-material/Warning';

//Icono para incidencias
import ErrorIcon from '@mui/icons-material/Error';
//Icono para descargar PDF
import DownloadPDF_Icon from '@mui/icons-material/FileDownload';
//Iconos para sumar / restar contador año para Calendario Tareas
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
//Icono para subir al principio de la pagina
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { isNull } from 'lodash';

import './HomeCliente.css'

import { DocumentosAgrupados } from './DocumentosAgrupados/DocumentosAgrupados';

import { ObservacionesElementos } from './ObservacionesElementos/ObservacionesElementos';
import { ComentariosElementos } from './ComentariosElementos/ComentariosElementos';
import { ComentariosElementosNoFQ } from './ComentariosElementos/ComentariosElementosNoFQ';
import { calcularValorParametroCalculado } from '../helpers/calculadorParametros';

import { useLocation } from 'react-router-dom';
import { Label } from '@mui/icons-material';
import { getObservacionesByElementoId } from '../api/observacionesElementos';
import FlowWithProvider from './DiagramaEditableEdges/components/FlowWithProvider';
import { ConnectionLine } from './DiagramaEditableEdges/edges/ConnectionLine';

//David pestanyes
const TabPanel = ({ children, value, index }) => {
    return (
        <div role="tabpanel" hidden={value !== index}>
            {value === index && <div>{children}</div>}
        </div>
    );
};
//David pestanyes


const HomeCliente = () => {

    // Guardado de datos
    const [clientes, setClientes] = useState([]);
    const [ofertas, setOfertas] = useState([]);
    const [parametros, setParametros] = useState([]);
    const [tareas, setTareas] = useState([]);
    const [analisis, setAnalisis] = useState([]);
    const [tareasFiltradas, setTareasFiltradas] = useState([]);
    const [parametrosAnalisisFiltrados, setParametrosAnalisisFiltrados] = useState([]);
    const [plantaActiva, setPlantaActiva] = useState({});

    // David
    // ficherosAnalisis hace peticion get y devuelve todos los registros de las tabla GES_Ficheros
    const [ficherosAll, setFicheros] = useState([]);

    // operarios hace peticion get y devuelve todos los usuarios de la tabla SYS_Usuarios
    const [operarios, setOperarios] = useState([]);

    //parametrosIncidencias son solo los parametrosAnalisisFiltrados donde el campo Observaciones tiene contenido
    const [parametrosIncidencias, setIncidencias] = useState([]); //parametrosIncidencias son solo los parametrosAnalisisFiltrados donde el campo Observaciones tiene contenido
    const [parametrosPDF, setPDF_Analisis] = useState([]); //parametrosPDF son solo los parametrosAnalisisFiltrados donde el campo pdf es <> 0 o diferente null
    //// David

    const [incidenciasElementoGeneralSeleccionado, setIncidenciasElementoGeneralSeleccionado] = useState([]);
    const [elementoGeneralSeleccionado, setElementoGeneralSeleccionado] = useState(undefined);

    const [clienteSeleccionado, setClienteSeleccionado] = useState({
        id: 0,
        nombreCliente: "",
        codigoCliente: 0,
        oferta: 0,
        descripcion: '',
        referencia: ''
    })

    const [clienteTarea, setClienteTarea] = useState({
        id: 0,
        nombreCliente: "",
        codigoCliente: 0,
        oferta: 0,
    })

    const [observaciones, setObservaciones] = useState([]);
    const [observacion, setObservacion] = useState({
        id: 0,
        idElemento: 0,
        observacion: '',
        nombreUsuario: '',
        apellidosUsuario: '',
        fecha: null,
        verCliente: false,
        verInsp: false
    })

    const [observacionEditar, setObservacionEditar] = useState({
        id: 0,
        idElemento: 0,
        observacion: '',
        nombreUsuario: '',
        apellidosUsuario: '',
        fecha: null,
        verCliente: false,
        verInsp: false
    })

    // Variables para el diagrama
    const [nodos, setNodos, onNodesChange] = useNodesState([]);
    const [lados, setLados, onEdgesChange] = useEdgesState([]);
    const { nodeTypesDashboard, nodeTypes } = useDiagrama();

    // Variables de contexto
    const { user } = useContext(AuthContext);
    const { elementoActivo, elementosGeneral, parametroActivo, analisisActivo, analisisParametros, parametrosFiltrados, setElementoActivo, setAnalisisActivo, handleSeleccionarParametro, handleCargarTodosElementosPlanta, handleSeleccionarAnalisis, GetParametrosAnalisisPlanta, GetValoresParametros, valoresParametrosNoFQ, parametrosFiltradosNoFQ, parametrosAnalisis, GetParametrosFiltradosSinSeleccionarElemento, descargarPdf } = useContext(DashboardContext);

    //Variables filtros
    const [inputCodigoCliente, setInputCodigoCliente] = useState('');
    const [inputNombreCliente, setInputNombreCliente] = useState('');
    const [inputReferencia, setInputReferencia] = useState('');
    const [inputDescripcion, setInputDescripcion] = useState('');
    const [inputOferta, setInputOferta] = useState('');

    const { state } = useLocation();

    useEffect(() => {

        setElementoActivo({})
        setAnalisisActivo({})
        setElementoGeneralSeleccionado(undefined)

    }, [])

    // Efecto que realiza las peticiones al cargar la página
    useEffect(() => {

        getUsuarios()
            .then(resp => setOperarios(resp.filter(op => !op.deleted)));

        getFicheros()
            .then(resp => setFicheros(resp.filter(fich => !fich.deleted)));

        getClientes()
            .then(resp => setClientes(resp.filter(cliente => !cliente.deleted)));

        getOfertas()
            .then(resp => setOfertas(resp.filter(oferta => !oferta.deleted)));

        getParametros()
            .then(resp => setParametros(resp.filter(param => !param.deleted)));

        getTareas()
            .then(resp => setTareas(resp.filter(tarea => !tarea.deleted)));

        getAnalisis()
            .then(resp => setAnalisis(resp.filter(an => !an.deleted)));

        setElementoActivo({})

        GetParametrosAnalisisPlanta()

        GetValoresParametros()
    }, []);

    // Efecto que carga el diagrama cada vez que se cambia de planta
    useEffect(() => {

        if (plantaActiva.diagrama) {
            const datosDiagrama = JSON.parse(plantaActiva.diagrama);
            setNodos(datosDiagrama.nodos);
            setLados(datosDiagrama.lados);

        } else {

            setNodos([]);
            setLados([]);

        }

        setTabActivoElementosGeneral(0);
        handleCargarTodosElementosPlanta(undefined, undefined);
        setElementoGeneralSeleccionado(undefined);
        handleCargarTodosElementosPlanta(plantaActiva.id, plantaActiva.oferta);
    }, [plantaActiva]);

    useEffect(() => {
        if (elementoGeneralSeleccionado === undefined && elementosGeneral.length > 0) {
            getObservacionesByElementoId(elementosGeneral[0].id).then(res => {
                setObservaciones(res);
            })
            GetParametrosFiltradosSinSeleccionarElemento(elementosGeneral[0].id);
            setElementoGeneralSeleccionado(elementosGeneral[0]);
        }

    }, [elementosGeneral]);

    useEffect(() => {
        if (state != null) {
            setClienteSeleccionado(prevParam => ({ ...prevParam, codigoCliente: state.codigoCliente, nombreCliente: state.nombreCliente, oferta: state.oferta }));

            getConfPlantaClientePorClienteOferta(state.codigoCliente, state.oferta)
                .then(res => res ? setPlantaActiva(res) : setPlantaActiva({}));
        }
    }, [state]);

    // Efecto que filtra las tareas al cambiar los datos de planta activa
    useEffect(() => {
        if (plantaActiva.codigoCliente) {
            setTareasFiltradas(tareas.filter(tarea => tarea.codigoCliente === plantaActiva.codigoCliente && tarea.oferta === plantaActiva.oferta && parseInt(tarea.elemento, 10) === elementoActivo.id));
        }

        if (elementoActivo.nombre) {
            const scroll = document.getElementById("scroll");
            scroll.scrollIntoView({ behavior: "smooth" });
        }

        setIncidencias(parametrosFiltrados.filter(inc => inc.observaciones !== ""));

        //Aqui filtramos los prametrosAnalisisFiltrados que tengan Observaciones
        setPDF_Analisis(parametrosFiltrados.filter(pdf => pdf.pdf !== null && pdf.pdf !== 0));

        // handleCargarTodosElementosPlanta( plantaActiva.id, plantaActiva.oferta);

    }, [plantaActiva, elementoActivo, parametrosFiltrados]);

    useEffect(() => {

        if (clienteSeleccionado.oferta != "" && clientes.length > 0) {
            const oferta = ofertas.filter(ofert => ofert.numeroOferta === clienteSeleccionado.oferta && !ofert.deleted)[0];
            if (oferta) {

                handleSeleccionOferta({ target: { textContent: oferta.numeroOferta.toString() } });
            }
        }
    }, [clienteSeleccionado.oferta]);

    useEffect(() => {
        if (clienteSeleccionado.descripcion != "" && clientes.length > 0) {
            const nombre = ofertas.filter(oferta => oferta.descripcion === clienteSeleccionado.descripcion);
            (nombre.length > 0) && setClienteSeleccionado({
                ...clienteSeleccionado,
                codigoCliente: nombre[0].codigoCliente,
                nombreCliente: nombre[0].nombreCliente,
                referencia: nombre[0].referencia,
                oferta: nombre[0].numeroOferta
            })

        }
    }, [clienteSeleccionado.descripcion])

    const ChartContainer = () => (
        <Chart style={{ height: '500px' }}>
            <ChartCategoryAxis>
                <ChartCategoryAxisItem
                    title={{
                        text: "Meses",
                    }}
                    categories={["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"]}
                />
            </ChartCategoryAxis>
            <ChartSeries>
                <ChartSeriesItem type="line" data={parametroActivo.datos} />
            </ChartSeries>
        </Chart>
    );

    // Con esta función, al seleccionar una oferta seteamos la planta activa
    const handleSeleccionOferta = (e) => {

        setElementoActivo({});
        setAnalisisActivo({});

        const ofertaSeleccionada = parseInt(e.target.textContent);

        getConfPlantaClientePorClienteOferta(clienteSeleccionado.codigoCliente, ofertaSeleccionada)
            .then(res => res ? setPlantaActiva(res) : setPlantaActiva({}));
    }

    const handleSeleccionOferta2 = (e) => {

        const ofertaSeleccionada = parseInt(e.target.textContent);

        setClienteSeleccionado({
            ...clienteSeleccionado,
            oferta: e.target.textContent
        })

        getConfPlantaClientePorClienteOferta(clienteSeleccionado.codigoCliente, ofertaSeleccionada)
            .then(res => res ? setPlantaActiva(res) : setPlantaActiva({}));
    }

    //Buscar nombre fichero tabla GES_Files segun id pdf en Analisis
    const buscaNombreFicheroPorId = (pdf) => {
        if (pdf !== 0) {
            const ficheroEncontrado = ficherosAll.find(row => row.id === pdf);
            return ficheroEncontrado ? ficheroEncontrado.name : '';
        } else {
            return null
        }
    }
    //Buscar nombre fichero tabla GES_Files segun id pdf en Analisis

    //Buscar nombre operario tabla SYS_Usuarios segun id operario en Analisis
    const buscarNombreOperario = (operarioId) => {
        const operarioEncontrado = operarios.find(row => row.id === operarioId);
        return operarioEncontrado ? operarioEncontrado.nombre + ' ' + operarioEncontrado.apellidos : '';
    }
    //Buscar nombre operario tabla SYS_Usuarios segun id operario en Analisis

    //Contador para mover o simular desplazamiento año en calendario y parametros análisis, inicializando al año fecha sistema (2 Contadores)
    //Contador1 para Calendario Tareas
    const [contadorYear, setCount] = useState(new Date().getFullYear());

    const handleIncrementarContador = () => {
        setCount(contadorYear + 1);
    };

    const handleDecrementarContador = () => {
        if (contadorYear > 0) {
            setCount(contadorYear - 1);
        }
    };

    //Contador2 para Parametros del Anaálisis
    const [contadorYear2, setCount2] = useState(new Date().getFullYear());

    const handleIncrementarContador2 = () => {
        setCount2(contadorYear2 + 1);
        //Reactivamos pestaña PDF
        setActiveTab(0)
    };

    const handleDecrementarContador2 = () => {
        if (contadorYear2 > 0) {
            setCount2(contadorYear2 - 1);
            //Reactivamos pestaña PDF
            setActiveTab(0)
        }
    };
    //Contador para mover o simular desplazamiento año en calendario y parametros análisis, inicializando al año fecha sistema (2 Contadores)

    //Pestanyes David
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleTabClick = (tabIndex, nombre, datos) => { //Activa penstaña Grafico
        setActiveTab(tabIndex)

        handleSeleccionarParametro({ nombre: nombre, datos: datos })
    }
    //Pestanyes David

    const [tabActivoElementosGeneral, setTabActivoElementosGeneral] = useState(0);

    const handleTabElementosGeneralChange = (event, value) => {
        setTabActivoElementosGeneral(value);
        setAnalisisActivo({})
    }

    const handleTabElementoGeneralClick = (elemento) => {
        setElementoGeneralSeleccionado(elemento);
        GetParametrosFiltradosSinSeleccionarElemento(elemento.id);
    }
    useEffect(() => {
        if (elementoGeneralSeleccionado) {
            setIncidenciasElementoGeneralSeleccionado(parametrosFiltrados.filter(elm => elm.elemento === elementoGeneralSeleccionado.id && elm.observaciones !== ""))
        }

    }, [parametrosFiltrados])

    // useEffect(()=>{
    //     setTabActivoElementosGeneral(0);
    // }, [elementoGeneralSeleccionado]);

    //PopUp David per mostrar incidencia   
    const [open, setOpen] = useState(false);

    const handleOpenClosePopUp = () => {
        setOpen(!open);
    }

    const Popup = ({ open, onClose, incidencia }) => {
        return (
            <Dialog
                open={open}
                maxWidth="sm"
                fullWidth
                onClose={onClose}
            >
                <DialogTitle>Incidéncia</DialogTitle>
                <DialogContent>
                    <TextField
                        multiline
                        rows={6}
                        value={incidencia}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleOpenClosePopUp()}>CERRAR</Button>
                </DialogActions>
            </Dialog>
        );
    };
    //PopUp David per mostrar incidencia

    //Posicionar al principio página al hacer clic en botón
    const handleInicioPagina = () => {
        //posiciona la página al principio
        window.scroll(0, 0);
    }
    //Posicionar al principio página al hacer clic en botón

    //Posicionar al final página al hacer clic en botón
    const handleScrollToBottom = () => {
        window.scrollTo(0, document.body.scrollHeight);
    };
    //Posicionar al final página al hacer clic en botón


    //Ordenacion por columnas (Apartado Incidencias)
    const [ordenColumnaIncidencias, setOrdenColumnaIncidencia] = useState(null);
    const [ordenAscendenteIncidencia, setOrdenAscendenteIncidencia] = useState(true);

    const manejarOrdenColumnaIncidencia = (nombreColumna) => {
        if (ordenColumnaIncidencias === nombreColumna) {
            setOrdenAscendenteIncidencia(!ordenAscendenteIncidencia);
        }
        else {
            setOrdenColumnaIncidencia(nombreColumna);
            setOrdenAscendenteIncidencia(true);
        }
    };

    // Ordenar los datos según la columna seleccionada fecha y el orden ascendente/descendente
    const datosOrdenadosIncdencias = ordenColumnaIncidencias
        ? parametrosIncidencias.sort((a, b) => {
            const valorA = a[ordenColumnaIncidencias];
            const valorB = b[ordenColumnaIncidencias];
            if (valorA < valorB) {
                return ordenAscendenteIncidencia ? -1 : 1;
            }
            if (valorA > valorB) {
                return ordenAscendenteIncidencia ? 1 : -1;
            }
            return 0;
        })
        : parametrosIncidencias;
    //Ordenacion por columnas (Apartado Incidencias)

    //Ordenacion por columnas (Apartado PDF'S)
    const [ordenColumnaPDF, setOrdenColumnaPDF] = useState(null);
    const [ordenAscendentePDF, setOrdenAscendentePDF] = useState(true);

    const manejarOrdenColumnaPDF = (nombreColumna) => {
        if (ordenColumnaPDF === nombreColumna) {
            setOrdenAscendentePDF(!ordenAscendentePDF);
        }
        else {
            setOrdenColumnaPDF(nombreColumna);
            setOrdenAscendentePDF(true);
        }
    };

    // Ordenar los datos según la columna seleccionada fecha y el orden ascendente/descendente
    const datosOrdenadosPDF = ordenColumnaPDF
        ? parametrosPDF.sort((a, b) => {
            const valorA = a[ordenColumnaPDF];
            const valorB = b[ordenColumnaPDF];
            if (valorA < valorB) {
                return ordenAscendentePDF ? -1 : 1;
            }
            if (valorA > valorB) {
                return ordenAscendentePDF ? 1 : -1;
            }
            return 0;
        })
        : parametrosPDF;

    function filtrarCodigoCliente(cliente) {
        if (!cliente.deleted) {
            if (inputCodigoCliente === '') {
                return true;
            } else {
                if (cliente.codigo?.toString().indexOf(inputCodigoCliente) >= 0) {
                    return true;
                } else {
                    return false;
                }
            }
        } else {
            return false;
        }
    }

    function filtrarNombreCliente(cliente) {
        if (!cliente.deleted) {
            if (inputNombreCliente === '') {
                return true;
            } else {
                const nombreClienteLowerCase = cliente.razonSocial ? cliente.razonSocial.toString().toLowerCase() : '';
                const inputNombreClienteLowerCase = inputNombreCliente.toLowerCase();
                return nombreClienteLowerCase.includes(inputNombreClienteLowerCase);
            }
        } else {
            return false;
        }
    }

    function filtrarReferencia(oferta) {
        if (!oferta.deleted && oferta.codigoCliente === user.idCliente) {
            if (inputReferencia === '') {
                return true;
            } else {
                if (oferta.referencia?.toString().indexOf(inputReferencia) >= 0) {
                    return true;
                } else {
                    return false;
                }
            }
        } else {
            return false;
        }
    }

    function filtrarDescripcion(oferta) {
        if (!oferta.deleted && oferta.codigoCliente === user.idCliente) {
            if (inputDescripcion === '') {
                return true;
            } else {
                if (oferta.descripcion?.toString().indexOf(inputDescripcion) >= 0) {
                    return true;
                } else {
                    return false;
                }
            }
        } else {
            return false;
        }
    }

    const resultadoParametroCalculado = (row, val, indiceMes, indiceAño) => {
        //row es donde están los parametros calculo
        if (row.parametrosCalculo === '') {
            return;
        }
        var idParametrosNecesariosCalculo = row.parametrosCalculo.split(";");

        const valoresParaCalcularFormula = []
        idParametrosNecesariosCalculo.map(id => {
            // const fecha = new Date(val.fecha);
            const auxAnalisisFiltradosElementos = analisisParametros.filter(param => param.parametro === Number(id) && new Date(param.fecha).getMonth() === indiceMes && new Date(param.fecha).getFullYear() === indiceAño && param.fecha !== null);
            if (auxAnalisisFiltradosElementos[0] !== undefined) {
                if (typeof auxAnalisisFiltradosElementos[0].valor === 'string') {
                    auxAnalisisFiltradosElementos[0].valor = auxAnalisisFiltradosElementos[0].valor.replace(",", ".");
                }
                valoresParaCalcularFormula.push(Number(auxAnalisisFiltradosElementos[0].valor))
            }
        })
        return calcularValorParametroCalculado(row.idParametroCalculado, valoresParaCalcularFormula)
    }

    return (
        <>
            {user.idPerfil !== 2 ?
                <Grid container spacing={2}>

                    {/* APARTADO INICIAL, SELECCIÓN DE PLANTA */}
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2} sx={{ alignItems: 'center' }}>

                                    <Grid item xs={6}>
                                        <Typography variant="h6">
                                            {
                                                plantaActiva.nombreCliente
                                                    ? plantaActiva.nombreCliente
                                                    : 'Selecciona un cliente'
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        {
                                            plantaActiva.descripcion && (
                                                <Typography>{plantaActiva.descripcion}</Typography>
                                            )
                                        }
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Autocomplete
                                            id="nombreCliente"
                                            options={clientes}
                                            value={clientes.find(cliente => cliente.razonSocial === clienteSeleccionado.nombreCliente) || null}
                                            filterOptions={options => clientes.filter((cliente) => filtrarNombreCliente(cliente))}
                                            onInputChange={(event, newInputValue) => {
                                                setInputNombreCliente(newInputValue);
                                            }}
                                            getOptionLabel={option => option.razonSocial}
                                            renderInput={params => <TextField {...params} label="Nombre Cliente" name="nombreCliente" />}
                                            onChange={(event, value) => setClienteSeleccionado(prevState => ({
                                                ...prevState,
                                                nombreCliente: value ? value.razonSocial : null,
                                                codigoCliente: value ? parseInt(value.codigo) : null,
                                                oferta: ''
                                            }))}
                                        />
                                    </Grid>

                                    <Grid item xs={2}>
                                        <Autocomplete
                                            id="clientes"
                                            options={clientes}
                                            value={clientes.find(cliente => cliente.codigo === clienteSeleccionado.codigoCliente) || null}
                                            filterOptions={options => clientes.filter((cliente) => filtrarCodigoCliente(cliente))}
                                            onInputChange={(event, newInputValue) => {
                                                setInputCodigoCliente(newInputValue);
                                            }}
                                            getOptionLabel={option => option.codigo.toString()}
                                            renderInput={params => <TextField {...params} label="Código Cliente" name="codigoCliente" />}
                                            onChange={(event, value) => setClienteSeleccionado(prevState => ({
                                                ...prevState,
                                                codigoCliente: value ? parseInt(value.codigo) : null,
                                                nombreCliente: value ? value.razonSocial : null,
                                                oferta: ''
                                            }))}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Autocomplete
                                            id="clientes"
                                            options={ofertas}
                                            value={ofertas.find(oferta => oferta.numeroOferta === clienteSeleccionado.oferta) || null}
                                            filterOptions={options => {
                                                if (clienteSeleccionado.nombreCliente !== "" && clienteSeleccionado.codigoCliente !== 0) {
                                                    return options.filter(oferta =>
                                                        oferta.nombreCliente === clienteSeleccionado.nombreCliente && oferta.codigoCliente === clienteSeleccionado.codigoCliente && !oferta.deleted
                                                    );
                                                } else {
                                                    return options.filter(oferta => !oferta.deleted);
                                                }
                                            }}
                                            onInputChange={(event, newInputValue) => {
                                                setInputOferta(newInputValue);
                                            }}
                                            getOptionLabel={option => option.numeroOferta.toString()}
                                            renderInput={params => <TextField {...params} label="Oferta" name="oferta" />}
                                            onChange={(event, value) => setClienteSeleccionado(prevState => ({
                                                ...prevState,
                                                codigoCliente: value ? parseInt(value.codigoCliente) : null,
                                                nombreCliente: value ? value.nombreCliente : null,
                                                oferta: value ? value.numeroOferta : null
                                            }))}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* APARTADO DEL DIAGRAMA */}
                    <Grid item xs={12}>
                        <Card>
                            <CardContent sx={{ p: 0 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" sx={{ pt: 1, pb: 1, pl: 2 }}>Diagrama de la planta</Typography>
                                    </Grid>
                                    {/* <Grid item xs={12} sx={{ height: 950 }}> */}
                                    <Grid item xs={12} sx={{ height: 600 }}>
                                        <FlowWithProvider
                                            nodes={nodos}
                                            edges={lados}
                                            onEdgesChange={onEdgesChange}
                                            onNodesChange={onNodesChange}
                                            nodeTypes={nodeTypesDashboard}
                                            connectionLineComponent={ConnectionLine}
                                        >
                                        </FlowWithProvider>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Trampilla para posicionar pantalla al hacer clic en elemento del diagrama */}
                    <Grid item id='scroll' xs={12}></Grid>
                    <Grid item xs={12}></Grid>
                    {/* Trampilla para posicionar pantalla al hacer clic en elemento del diagrama */}

                    {/*Apartado incidencias */}
                    <Grid item xs={6} id='scroll'>
                        <Card style={{ height: '600px', overflowY: 'auto' }}>
                            <CardContent sx={{ p: 2 }}>

                                <Grid container spacing={2}>

                                    <Grid item xs={12} sx={{ pb: 2 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <Typography variant="h6">Incidéncias</Typography>
                                            <h4 style={{ marginLeft: '10px', fontSize: '1.25rem' }} >{elementoActivo.nombre}</h4>
                                        </div>
                                    </Grid>
                                    {
                                        elementoActivo.nombre ? (
                                            <Grid item xs={12}>
                                                <ObservacionesElementos
                                                    idElemento={elementoActivo.id}
                                                    observaciones={observaciones}
                                                    setObservaciones={setObservaciones}
                                                    observacion={observacion}
                                                    setObservacion={setObservacion}
                                                    observacionEditar={observacionEditar}
                                                    setObservacionEditar={setObservacionEditar}
                                                >
                                                </ObservacionesElementos>
                                                <ComentariosElementosNoFQ
                                                    idElemento={elementoActivo.id}>
                                                </ComentariosElementosNoFQ>
                                            </Grid>
                                        )
                                            :
                                            elementosGeneral.length > 0 ? (
                                                <Grid item xs={12}>
                                                    <Tabs
                                                        value={tabActivoElementosGeneral}
                                                        onChange={handleTabElementosGeneralChange}
                                                        variant="scrollable"
                                                        scrollButtons="auto">
                                                        {
                                                            elementosGeneral.map((elemento, index) => {
                                                                const nombre = elemento.nombre;
                                                                const apellido = elemento.descripcion ? elemento.descripcion : elemento.numero
                                                                return (
                                                                    <Tab value={index}
                                                                        label={nombre + ' ' + apellido}
                                                                        sx={{ fontSize: '1.20 rem' }}
                                                                        onClick={() => handleTabElementoGeneralClick(elemento)} />
                                                                )
                                                            })
                                                        }
                                                    </Tabs>
                                                    {
                                                        elementosGeneral.map((elemento, index) => {
                                                            return (
                                                                <TabPanel value={tabActivoElementosGeneral}
                                                                    index={index}>
                                                                    <ObservacionesElementos
                                                                        idElemento={elemento.id}
                                                                        observaciones={observaciones}
                                                                        setObservaciones={setObservaciones}
                                                                        observacion={observacion}
                                                                        setObservacion={setObservacion}
                                                                        observacionEditar={observacionEditar}
                                                                        setObservacionEditar={setObservacionEditar}
                                                                    >
                                                                    </ObservacionesElementos>
                                                                    <ComentariosElementosNoFQ
                                                                        idElemento={elemento.id}>
                                                                    </ComentariosElementosNoFQ>
                                                                </TabPanel>
                                                            )
                                                        })
                                                    }
                                                </Grid>
                                            )
                                                : <></>
                                    }

                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* APARTADO CALENDARIO DE TAREAS POR ELEMENTO */}
                    <Grid item xs={6}>
                        <Card style={{ height: '600px', overflowY: 'auto' }}>
                            <CardContent>

                                <Grid container spacing={3} sx={{ mb: 5, justifyContent: 'space-between' }}>
                                    {
                                        elementoActivo.nombre ? (
                                            <>
                                                <Grid item>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <Typography variant="h6">Calendario de tareas</Typography>
                                                        <h4 style={{ marginLeft: '10px', fontSize: '1.25rem' }} >{elementoActivo.nombre}</h4>
                                                    </div>
                                                    <IconButton onClick={handleDecrementarContador}>
                                                        <NavigateBeforeIcon />
                                                    </IconButton>

                                                    <span>{contadorYear}</span>

                                                    <IconButton onClick={handleIncrementarContador}>
                                                        <NavigateNextIcon />
                                                    </IconButton>
                                                </Grid>
                                            </>
                                        ) : (
                                            <Grid item>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <Typography variant="h6">Calendario de tareas</Typography>
                                                    <h4 style={{ marginLeft: '10px', fontSize: '1.25rem' }} >{elementoActivo.nombre}</h4>
                                                </div>
                                                <IconButton onClick={handleDecrementarContador}>
                                                    <NavigateBeforeIcon />
                                                </IconButton>

                                                <span>{contadorYear}</span>

                                                <IconButton onClick={handleIncrementarContador}>
                                                    <NavigateNextIcon />
                                                </IconButton>
                                            </Grid>
                                        )
                                    }
                                </Grid>

                                <Grid item xs={12}>
                                    {
                                        elementoActivo.nombre ?
                                            (
                                                <TableContainer component={Paper}>
                                                    <Table sx={{ minWidth: 650 }}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell></TableCell>
                                                                <TableCell align="left">Tipo de análisis</TableCell>
                                                                <TableCell>Ene</TableCell>
                                                                <TableCell>Feb</TableCell>
                                                                <TableCell>Mar</TableCell>
                                                                <TableCell>Abr</TableCell>
                                                                <TableCell>May</TableCell>
                                                                <TableCell>Jun</TableCell>
                                                                <TableCell>Jul</TableCell>
                                                                <TableCell>Ago</TableCell>
                                                                <TableCell>Sep</TableCell>
                                                                <TableCell>Oct</TableCell>
                                                                <TableCell>Nov</TableCell>
                                                                <TableCell>Dic</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                elementoActivo.nombre &&
                                                                // Mapeamos todos los parametros
                                                                analisis.map(row => {

                                                                    // row -> id, nombre
                                                                    // tareasFiltradas -> analisis, elemento
                                                                    var currentTime = new Date();

                                                                    // Obtenemos todos los valores del parametro actual (valores del mismo parametro, enero, febrero, ...)
                                                                    const valoresPorTarea = parametrosFiltrados.filter(analisis => parseInt(analisis.analisis, 10) === row.id);

                                                                    let fechas = [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1]; // -1 = no existe registro, 0 = existe, pero no realizado, 1 = existe y realizado, 2 = tiene el check de Incorrecto o No Valido marcados
                                                                    let valorFechas = [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1];
                                                                    let valorFechasRealizado = [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1];
                                                                    if (valoresPorTarea.length > 0) {

                                                                        // Mapeamos los valores en un array, y los registro que no estén seteamos una raya
                                                                        valoresPorTarea.map(val => {
                                                                            // Convertimos la fecha del registro en un objeto de fecha
                                                                            const fecha = new Date(val.fecha);
                                                                            // Contamos solo si los registros son de este año
                                                                            //if (fecha.getFullYear() === currentTime.getFullYear()) {
                                                                            if (fecha.getFullYear() === contadorYear) {
                                                                                for (let i = 0; i < 12; i++) {
                                                                                    if (fecha.getMonth() === i) {
                                                                                        valorFechas[i] = fecha;
                                                                                        if (val.incorrecto || val.noValido) {
                                                                                            fechas[i] = 2
                                                                                            valorFechasRealizado[i] = new Date(val.fechaRealizado);
                                                                                        } else {
                                                                                            if (val.realizado) {
                                                                                                fechas[i] = 1
                                                                                                valorFechasRealizado[i] = new Date(val.fechaRealizado);
                                                                                            } else {
                                                                                                fechas[i] = 0
                                                                                            }
                                                                                        }
                                                                                        // val.realizado
                                                                                        //     ? fechas[i] = 1
                                                                                        //     : fechas[i] = 0
                                                                                    }
                                                                                }
                                                                            }

                                                                        });

                                                                    }

                                                                    // Devolvemos los valores
                                                                    return (
                                                                        valoresPorTarea.length > 0 && (
                                                                            <TableRow
                                                                                key={row.id}
                                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                            >
                                                                                <TableCell>
                                                                                    <Tooltip title="Ver parametros del elemento" placement="right">
                                                                                        <IconButton onClick={() => handleSeleccionarAnalisis(row.id)}>
                                                                                            <TimelineIcon />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </TableCell>
                                                                                <TableCell aligh="left" component="th" scope="row">
                                                                                    {row.nombre}
                                                                                </TableCell>
                                                                                {
                                                                                    fechas.map((fecha, index) => (
                                                                                        <TableCell key={index}>
                                                                                            <IconButton
                                                                                                onClick={() => { descargarPdf(row.id, valorFechas[index]) }}
                                                                                                color={fecha === -1 ? 'primary' : fecha === 0 ? 'error' : fecha === 2 ? 'warning' : 'success'}
                                                                                                disabled={fecha === -1 ? true : false}
                                                                                            >
                                                                                                {
                                                                                                    fecha === -1
                                                                                                        ? <RemoveIcon />
                                                                                                        : fecha === 0
                                                                                                            ? <ClearIcon />
                                                                                                            : fecha === 2
                                                                                                                ? <WarningIcon />
                                                                                                                : <CheckIcon />
                                                                                                }
                                                                                            </IconButton>
                                                                                            {
                                                                                                fecha === -1
                                                                                                    ? <p></p>
                                                                                                    : valorFechasRealizado[index] === -1
                                                                                                        ? <p>{valorFechas[index] != -1 ? valorFechas[index].toLocaleDateString() : ""}</p>
                                                                                                        : <p>{valorFechasRealizado[index] != -1 ? valorFechasRealizado[index].toLocaleDateString() : ""}</p>
                                                                                            }
                                                                                            {/* <p>{valorFechas[index] != -1 ? valorFechas[index].toLocaleDateString() : ""}</p> */}
                                                                                        </TableCell>
                                                                                    ))
                                                                                }
                                                                            </TableRow>
                                                                        )
                                                                    )
                                                                })
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            )
                                            :
                                            (
                                                elementosGeneral.length > 0 ? (
                                                    <Grid item xs={12}>
                                                        <Tabs
                                                            value={tabActivoElementosGeneral}
                                                            onChange={handleTabElementosGeneralChange}
                                                            variant="scrollable"
                                                            scrollButtons="auto">
                                                            {
                                                                elementosGeneral.map((elemento, index) => {
                                                                    const nombre = elemento.nombre;
                                                                    const apellido = elemento.descripcion ? elemento.descripcion : elemento.numero
                                                                    return (
                                                                        <Tab value={index}
                                                                            label={nombre + ' ' + apellido}
                                                                            sx={{ fontSize: '1.20 rem' }}
                                                                            onClick={() => handleTabElementoGeneralClick(elemento)} />
                                                                    )
                                                                })
                                                            }
                                                        </Tabs>
                                                        {
                                                            elementosGeneral.map((elemento, index) => {
                                                                return (
                                                                    <TabPanel value={tabActivoElementosGeneral}
                                                                        index={index}>
                                                                        <TableContainer component={Paper}>
                                                                            <Table sx={{ minWidth: 650 }}>
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <TableCell></TableCell>
                                                                                        <TableCell align="left">Tipo de análisis</TableCell>
                                                                                        <TableCell>Ene</TableCell>
                                                                                        <TableCell>Feb</TableCell>
                                                                                        <TableCell>Mar</TableCell>
                                                                                        <TableCell>Abr</TableCell>
                                                                                        <TableCell>May</TableCell>
                                                                                        <TableCell>Jun</TableCell>
                                                                                        <TableCell>Jul</TableCell>
                                                                                        <TableCell>Ago</TableCell>
                                                                                        <TableCell>Sep</TableCell>
                                                                                        <TableCell>Oct</TableCell>
                                                                                        <TableCell>Nov</TableCell>
                                                                                        <TableCell>Dic</TableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                    {
                                                                                        elementoGeneralSeleccionado &&
                                                                                        // Mapeamos todos los parametros
                                                                                        analisis.map(row => {

                                                                                            // row -> id, nombre
                                                                                            // tareasFiltradas -> analisis, elemento
                                                                                            var currentTime = new Date();
                                                                                            // Obtenemos todos los valores del parametro actual (valores del mismo parametro, enero, febrero, ...)
                                                                                            const valoresPorTarea = parametrosFiltrados.filter(analisis => parseInt(analisis.analisis, 10) === row.id);

                                                                                            let fechas = [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1]; // -1 = no existe registro, 0 = existe, pero no realizado, 1 = existe y realizado, 2 = tiene el check de Incorrecto o No Valido marcados
                                                                                            let valorFechas = [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1];
                                                                                            let valorFechasRealizado = [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1];
                                                                                            if (valoresPorTarea.length > 0) {

                                                                                                // Mapeamos los valores en un array, y los registro que no estén seteamos una raya
                                                                                                valoresPorTarea.map(val => {
                                                                                                    // Convertimos la fecha del registro en un objeto de fecha
                                                                                                    const fecha = new Date(val.fecha);
                                                                                                    // Contamos solo si los registros son de este año
                                                                                                    if (fecha.getFullYear() === contadorYear) {
                                                                                                        for (let i = 0; i < 12; i++) {
                                                                                                            if (fecha.getMonth() === i) {
                                                                                                                valorFechas[i] = fecha;
                                                                                                                if (val.incorrecto || val.noValido) {
                                                                                                                    fechas[i] = 2
                                                                                                                    valorFechasRealizado[i] = new Date(val.fechaRealizado);
                                                                                                                } else {
                                                                                                                    if (val.realizado) {
                                                                                                                        fechas[i] = 1
                                                                                                                        valorFechasRealizado[i] = new Date(val.fechaRealizado);
                                                                                                                    } else {
                                                                                                                        fechas[i] = 0
                                                                                                                    }
                                                                                                                }
                                                                                                                // val.realizado
                                                                                                                //     ? fechas[i] = 1
                                                                                                                //     : fechas[i] = 0
                                                                                                            }
                                                                                                        }
                                                                                                    }

                                                                                                });

                                                                                            }

                                                                                            // Devolvemos los valores
                                                                                            return (
                                                                                                valoresPorTarea.length > 0 && (
                                                                                                    <TableRow
                                                                                                        key={row.id}
                                                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                                                    >
                                                                                                        <TableCell>
                                                                                                            <Tooltip title="Ver parametros del elemento" placement="right">
                                                                                                                <IconButton onClick={() => handleSeleccionarAnalisis(row.id)}>
                                                                                                                    <TimelineIcon />
                                                                                                                </IconButton>
                                                                                                            </Tooltip>
                                                                                                        </TableCell>
                                                                                                        <TableCell aligh="left" component="th" scope="row">
                                                                                                            {row.nombre}
                                                                                                        </TableCell>
                                                                                                        {
                                                                                                            fechas.map((fecha, index) => (
                                                                                                                <TableCell key={index}>
                                                                                                                    <IconButton
                                                                                                                        onClick={() => { descargarPdf(row.id, valorFechas[index]) }}
                                                                                                                        color={fecha === -1 ? 'primary' : fecha === 0 ? 'error' : fecha === 2 ? 'warning' : 'success'}
                                                                                                                        disabled={fecha === -1 ? true : false}
                                                                                                                    >
                                                                                                                        {
                                                                                                                            fecha === -1
                                                                                                                                ? <RemoveIcon />
                                                                                                                                : fecha === 0
                                                                                                                                    ? <ClearIcon />
                                                                                                                                    : fecha === 2
                                                                                                                                        ? <WarningIcon />
                                                                                                                                        : <CheckIcon />
                                                                                                                        }
                                                                                                                    </IconButton>
                                                                                                                    {
                                                                                                                        fecha === -1
                                                                                                                            ? <p></p>
                                                                                                                            : valorFechasRealizado[index] === -1
                                                                                                                                ? <p>{valorFechas[index] != -1 ? valorFechas[index].toLocaleDateString() : ""}</p>
                                                                                                                                : <p>{valorFechasRealizado[index] != -1 ? valorFechasRealizado[index].toLocaleDateString() : ""}</p>
                                                                                                                    }
                                                                                                                    {/* <p>{valorFechas[index] != -1 ? valorFechas[index].toLocaleDateString() : ""}</p> */}
                                                                                                                </TableCell>
                                                                                                            ))
                                                                                                        }
                                                                                                    </TableRow>
                                                                                                )
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </TableBody>
                                                                            </Table>
                                                                        </TableContainer>
                                                                    </TabPanel>
                                                                )
                                                            })
                                                        }
                                                    </Grid>
                                                )
                                                    : <></>
                                            )
                                    }
                                </Grid>

                            </CardContent>
                        </Card>
                    </Grid>

                    {/* FIN APARTADO CALENDARIO DE TAREAS */}
                    <Grid container align="right">
                        <Grid item xs={12}>
                            <IconButton onClick={() => handleScrollToBottom()}>
                                <ArrowDownwardIcon fontSize="large" />
                            </IconButton>
                            <IconButton onClick={() => handleInicioPagina()}>
                                <ArrowUpwardIcon fontSize="large" />
                            </IconButton>
                        </Grid>
                    </Grid>


                    {/* APARTADO TABLA DE PARAMETROS */}
                    <Grid item xs={6}>
                        <Card style={{ height: '600px', overflowY: 'auto' }}>
                            <CardContent>
                                <Grid container spacing={3} sx={{ mb: 5, justifyContent: 'space-between' }}>
                                    {
                                        analisisActivo.nombre ? (
                                            <>
                                                <Grid item>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <Typography variant='h6'>Parámetros del Análisis</Typography>
                                                        {
                                                            elementoActivo.nombre ? (
                                                                <h4 style={{ marginLeft: '10px', fontSize: '1.25rem' }} >{elementoActivo.nombre}</h4>
                                                            )
                                                                :
                                                            (
                                                                elementoGeneralSeleccionado !== undefined && (
                                                                    <h4 style={{ marginLeft: '10px', fontSize: '1.25rem' }} >{elementoGeneralSeleccionado.descripcion !== null ? elementoGeneralSeleccionado.nombre + " " + elementoGeneralSeleccionado.descripcion : elementoGeneralSeleccionado.nombre + " " + elementoGeneralSeleccionado.numero}</h4>
                                                                )                                                            
                                                            )
                                                                
                                                        }
                                                    </div>
                                                    <IconButton onClick={handleDecrementarContador2}>
                                                        <NavigateBeforeIcon />
                                                    </IconButton>

                                                    <span>{contadorYear2}</span>

                                                    <IconButton onClick={handleIncrementarContador2}>
                                                        <NavigateNextIcon />
                                                    </IconButton>
                                                </Grid>
                                            </>
                                        ) : (
                                            <Grid item>
                                                <Typography variant='h6'>Selecciona un análisis del calendario</Typography>
                                                <IconButton onClick={handleDecrementarContador2}>
                                                    <NavigateBeforeIcon />
                                                </IconButton>

                                                <span>{contadorYear2}</span>

                                                <IconButton onClick={handleIncrementarContador2}>
                                                    <NavigateNextIcon />
                                                </IconButton>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                                {
                                    analisisActivo.nombre && (
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell></TableCell>
                                                        <TableCell align="left">Parámetro</TableCell>
                                                        <TableCell>Ene</TableCell>
                                                        <TableCell>Feb</TableCell>
                                                        <TableCell>Mar</TableCell>
                                                        <TableCell>Abr</TableCell>
                                                        <TableCell>May</TableCell>
                                                        <TableCell>Jun</TableCell>
                                                        <TableCell>Jul</TableCell>
                                                        <TableCell>Ago</TableCell>
                                                        <TableCell>Sep</TableCell>
                                                        <TableCell>Oct</TableCell>
                                                        <TableCell>Nov</TableCell>
                                                        <TableCell>Dic</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        analisisActivo.tipo === 0 ?
                                                            // Mapeamos todos los parametros
                                                            parametros.map(row => {
                                                                // Obtenemos todos los valores del parametro actual (valores del mismo parametro, enero, febrero, ...)
                                                                const valoresPorParametro = analisisParametros.filter(param => param.parametro === row.id && param.fecha !== null);
                                                                let fechas = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

                                                                // Mapeamos los valores en un array, y si no hay datos seteamos un 0
                                                                valoresPorParametro.map(val => {
                                                                    const fecha = new Date(val.fecha);

                                                                    if (fecha.getFullYear() === contadorYear2) {
                                                                        for (let i = 0; i < 12; i++) {
                                                                            if (fecha.getMonth() === i) {
                                                                                if (row.esCalculado === true) {
                                                                                    fechas[i] = resultadoParametroCalculado(row, val, i, contadorYear2);

                                                                                } else {

                                                                                    if (val.valor && typeof val.valor === 'string' && val.valor.includes(',')) {
                                                                                        const nuevoValor = val.valor.replace(',', '.');
                                                                                        fechas[i] = nuevoValor;
                                                                                    } else {
                                                                                        fechas[i] = val.valor;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                });

                                                                // Devolvemos los valores
                                                                return (
                                                                    valoresPorParametro.length > 0 && (
                                                                        <TableRow
                                                                            key={row.id}
                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                        >
                                                                            <TableCell>
                                                                                <Tooltip title="Ver en la gráfica" placement="right">
                                                                                    {/* <IconButton onClick={() => handleSeleccionarParametro({ nombre: row.nombre, datos: fechas })}> */}
                                                                                    {/* Se modifica para que al hacer clic en boton cambie automaticamente de pestaña y pasamos los parametros para dibujar gráfico  */}
                                                                                    <IconButton onClick={() => handleTabClick(1, row.nombre, fechas)}>
                                                                                        <TimelineIcon />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </TableCell>
                                                                            <TableCell aligh="left" component="th" scope="row">
                                                                                {row.nombre}
                                                                            </TableCell>
                                                                            {
                                                                                fechas.map((fecha, index) => {
                                                                                    if (fecha === -100) {
                                                                                        return <TableCell key={index} style={{ backgroundColor: 'rgba(255, 0, 0, 0.6)' }}>{""}</TableCell>
                                                                                        // <TableCell key={index}>{fecha}</TableCell>
                                                                                    } else {
                                                                                        return <TableCell key={index}>{fecha}</TableCell>
                                                                                    }
                                                                                }
                                                                                )
                                                                            }
                                                                        </TableRow>
                                                                    )
                                                                )
                                                            })
                                                            :
                                                            (parametrosFiltradosNoFQ.length > 0 && (analisisActivo.nombre.includes('Legionela') || analisisActivo.nombre.includes('Aerobios'))) && (
                                                                (() => {
                                                                    const fechas = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

                                                                    // Organizar los resultados por mes
                                                                    parametrosFiltradosNoFQ.forEach(val => {
                                                                        const fecha = new Date(val.fecha);

                                                                        if (fecha.getFullYear() === contadorYear2) {
                                                                            fechas[fecha.getMonth()] = val.resultado;
                                                                        }
                                                                    });

                                                                    return (
                                                                        // Renderizar una fila con los resultados organizados
                                                                        <TableRow
                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                        >
                                                                            <TableCell>
                                                                                <Tooltip title="Ver en la gráfica" placement="right">
                                                                                    {/* <IconButton onClick={() => handleSeleccionarParametro({ nombre: row.nombre, datos: fechas })}> */}
                                                                                    {/* Se modifica para que al hacer clic en boton cambie automaticamente de pestaña y pasamos los parametros para dibujar gráfico  */}
                                                                                    <IconButton onClick={() => handleTabClick(1, analisisActivo.nombre, fechas)}>
                                                                                        <TimelineIcon />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </TableCell>
                                                                            <TableCell align="left" component="th" scope="row">
                                                                                {analisisActivo.nombre}
                                                                            </TableCell>
                                                                            {/* Mostrar los resultados de cada mes */}
                                                                            {fechas.map((fecha, idx) => (
                                                                                <TableCell key={idx}>{fecha != "" ? fecha : "0"}</TableCell>
                                                                            ))}
                                                                        </TableRow>
                                                                    )
                                                                })()
                                                            )
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    )
                                }

                            </CardContent>
                        </Card>
                    </Grid>

                    {/* APARTADO DOCUMENTOS */}
                    <Grid item xs={6}>
                        <Card style={{ height: '600px', overflowY: 'auto' }}>
                            <CardContent sx={{ p: 2 }}>
                                {
                                    elementoActivo.nombre ?
                                        (
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <h4 style={{ marginLeft: '10px', fontSize: '1.25rem' }} >{elementoActivo.nombre}</h4>
                                                    <Tabs
                                                        value={activeTab}
                                                        onChange={handleTabChange}
                                                        indicatorColor="primary"
                                                        textColor="primary"
                                                    /*centered*/
                                                    >
                                                        <Tab label="Documentos" />
                                                        <Tab label="Gráfico" />
                                                    </Tabs>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TabPanel value={activeTab} index={0}>
                                                        {/* Contingut del grid PDF'S */}
                                                        {/*Apartado PDF'S */}
                                                        <Grid item xs={12}>
                                                            <Card style={{ height: '600px', overflowY: 'auto' }}>
                                                                <CardContent sx={{ p: 2 }}>
                                                                    <DocumentosAgrupados idUsuario={user.idPerfil} planta={plantaActiva} cliente={clienteSeleccionado} elementoActivo={elementoActivo.nombre} />
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    </TabPanel>

                                                    <TabPanel value={activeTab} index={1}>
                                                        {/* Contingut del grid Gràfics */}
                                                        <Grid item xs={12}>
                                                            <Card style={{ height: '600px', overflowY: 'auto' }}>
                                                                <CardContent sx={{ p: 2 }}>

                                                                    <Grid container spacing={6} sx={{ mb: 2, justifyContent: 'space-between' }}>
                                                                        {
                                                                            parametroActivo.nombre ? (
                                                                                <>
                                                                                    <Grid item>
                                                                                        <Typography variant='h6'>Vista gráfica del parámetro</Typography>
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <Chip label={parametroActivo.nombre} color="primary" />
                                                                                    </Grid>
                                                                                </>
                                                                            ) : (
                                                                                <Grid item>
                                                                                    <Typography variant='h6'>Selecciona un parámetro de la tabla</Typography>
                                                                                </Grid>
                                                                            )
                                                                        }
                                                                    </Grid>

                                                                    <ChartContainer />

                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    </TabPanel>
                                                </Grid>
                                            </Grid>
                                        )
                                        :
                                        (
                                            elementoGeneralSeleccionado !== undefined &&
                                            (

                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <h4 style={{ marginLeft: '10px', fontSize: '1.25rem' }} >{elementoGeneralSeleccionado.descripcion !== null ? elementoGeneralSeleccionado.nombre + " " + elementoGeneralSeleccionado.descripcion : elementoGeneralSeleccionado.nombre + " " + elementoGeneralSeleccionado.numero}</h4>
                                                        <Tabs
                                                            value={activeTab}
                                                            onChange={handleTabChange}
                                                            indicatorColor="primary"
                                                            textColor="primary"
                                                        /*centered*/
                                                        >
                                                            <Tab label="Documentos" />
                                                            <Tab label="Gráfico" />
                                                        </Tabs>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TabPanel value={activeTab} index={0}>
                                                            {/* Contingut del grid PDF'S */}
                                                            {/*Apartado PDF'S */}
                                                            <Grid item xs={12}>
                                                                <Card style={{ height: '600px', overflowY: 'auto' }}>
                                                                    <CardContent sx={{ p: 2 }}>
                                                                        <DocumentosAgrupados idUsuario={user.idPerfil} planta={plantaActiva} cliente={clienteSeleccionado} elementoActivo={elementoGeneralSeleccionado.descripcion !== null ? elementoGeneralSeleccionado.nombre + " " + elementoGeneralSeleccionado.descripcion : elementoGeneralSeleccionado.nombre + " " + elementoGeneralSeleccionado.numero} />
                                                                    </CardContent>
                                                                </Card>
                                                            </Grid>
                                                        </TabPanel>

                                                        <TabPanel value={activeTab} index={1}>
                                                            {/* Contingut del grid Gràfics */}
                                                            <Grid item xs={12}>
                                                                <Card style={{ height: '600px', overflowY: 'auto' }}>
                                                                    <CardContent sx={{ p: 2 }}>

                                                                        <Grid container spacing={6} sx={{ mb: 2, justifyContent: 'space-between' }}>
                                                                            {
                                                                                parametroActivo.nombre ? (
                                                                                    <>
                                                                                        <Grid item>
                                                                                            <Typography variant='h6'>Vista gráfica del parámetro</Typography>
                                                                                        </Grid>
                                                                                        <Grid item>
                                                                                            <Chip label={parametroActivo.nombre} color="primary" />
                                                                                        </Grid>
                                                                                    </>
                                                                                ) : (
                                                                                    <Grid item>
                                                                                        <Typography variant='h6'>Selecciona un parámetro de la tabla</Typography>
                                                                                    </Grid>
                                                                                )
                                                                            }
                                                                        </Grid>

                                                                        <ChartContainer />
                                                                    </CardContent>
                                                                </Card>
                                                            </Grid>
                                                        </TabPanel>
                                                    </Grid>
                                                </Grid>
                                            )
                                        )
                                }
                            </CardContent>
                        </Card>
                    </Grid>


                    <Grid container align="right">
                        <Grid item xs={12}>
                            <IconButton onClick={() => handleInicioPagina()}>
                                <ArrowUpwardIcon fontSize="large" />
                            </IconButton>
                        </Grid>
                    </Grid>

                </Grid >
                :
                <Grid container spacing={2}>
                    {/* APARTADO INICIAL, SELECCIÓN DE PLANTA */}
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Grid container sx={{ alignItems: 'center' }}>

                                    <Grid item xs={4}>
                                        <Typography variant="h6">
                                            {
                                                plantaActiva.nombreCliente
                                                    ? plantaActiva.nombreCliente
                                                    : 'Selecciona un código de oferta'
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        {
                                            plantaActiva.descripcion && (
                                                <Typography>{plantaActiva.descripcion}</Typography>
                                            )
                                        }
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Autocomplete
                                            id="ofertas"
                                            options={ofertas}
                                            value={ofertas.find(oferta => oferta.numeroOferta === clienteSeleccionado.oferta && oferta.codigoCliente === user.idCliente) || null}
                                            filterOptions={options => ofertas.filter((oferta) => filtrarDescripcion(oferta))}
                                            onInputChange={(event, newInputValue) => {
                                                setInputDescripcion(newInputValue);
                                            }}
                                            getOptionLabel={option => option.descripcion}
                                            renderInput={params => <TextField {...params} label="Descripción" name="descripcion" />}
                                            onChange={(event, value) => setClienteSeleccionado(prevState => ({
                                                ...prevState,
                                                codigoCliente: value ? parseInt(value.codigoCliente) : null,
                                                nombreCliente: value ? value.nombreCliente : null,
                                                descripcion: value ? value.descripcion : null,
                                                referencia: value ? value.referencia : null,
                                                oferta: value ? value.numeroOferta : null,
                                            }))}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Autocomplete
                                            id="ofertas"
                                            options={ofertas}
                                            value={ofertas.find(oferta => oferta.descripcion === clienteSeleccionado.descripcion && oferta.codigoCliente === user.idCliente) || null}
                                            filterOptions={options => ofertas.filter((oferta) => filtrarReferencia(oferta))}
                                            onInputChange={(event, newInputValue) => {
                                                setInputReferencia(newInputValue);
                                            }}
                                            getOptionLabel={option => option.referencia}
                                            renderInput={params => <TextField {...params} label="Referencia" name="referencia" />}
                                            onChange={(event, value) => setClienteSeleccionado(prevState => ({
                                                ...prevState,
                                                codigoCliente: value ? parseInt(value.codigoCliente) : null,
                                                nombreCliente: value ? value.nombreCliente : null,
                                                descripcion: value ? value.descripcion : null,
                                                referencia: value ? value.referencia : null,
                                                oferta: value ? value.numeroOferta : null,
                                            }))}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Autocomplete
                                            id="clientes"
                                            options={ofertas}
                                            value={ofertas.find(oferta => oferta.numeroOferta === clienteSeleccionado.oferta) || null}
                                            filterOptions={options => {
                                                if (clienteSeleccionado.nombreCliente !== "" && clienteSeleccionado.codigoCliente !== 0) {
                                                    return options.filter(oferta =>
                                                        oferta.nombreCliente === clienteSeleccionado.nombreCliente && oferta.codigoCliente === clienteSeleccionado.codigoCliente && oferta.codigoCliente === user.idCliente && !oferta.deleted
                                                    );
                                                } else {
                                                    return options.filter(oferta => !oferta.deleted && oferta.codigoCliente === user.idCliente);
                                                }
                                            }}
                                            onInputChange={(event, newInputValue) => {
                                                setInputOferta(newInputValue);
                                            }}
                                            getOptionLabel={option => option.numeroOferta.toString()}
                                            renderInput={params => <TextField {...params} label="Oferta" name="oferta" />}
                                            onChange={(event, value) => setClienteSeleccionado(prevState => ({
                                                ...prevState,
                                                codigoCliente: value ? parseInt(value.codigoCliente) : null,
                                                nombreCliente: value ? value.nombreCliente : null,
                                                oferta: value ? value.numeroOferta : null,
                                                descripcion: value ? value.descripcion : null,
                                                referencia: value ? value.referencia : null
                                            }))}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* APARTADO DEL DIAGRAMA */}
                    <Grid item xs={12}>
                        <Card>
                            <CardContent sx={{ p: 0 }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" sx={{ pt: 1, pb: 1, pl: 2 }}>Diagrama de la planta</Typography>
                                    </Grid>
                                    {/* <Grid item xs={12} sx={{ height: 950 }}> */}
                                    <Grid item xs={12} sx={{ height: 600 }}>
                                        <FlowWithProvider
                                            nodes={nodos}
                                            edges={lados}
                                            onEdgesChange={onEdgesChange}
                                            onNodesChange={onNodesChange}
                                            nodeTypes={nodeTypesDashboard}
                                            connectionLineComponent={ConnectionLine}
                                        >
                                        </FlowWithProvider>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Trampilla para posicionar pantalla al hacer clic en elemento del diagrama */}
                    <Grid item id='scroll' xs={12}></Grid>
                    <Grid item xs={12}></Grid>
                    {/* Trampilla para posicionar pantalla al hacer clic en elemento del diagrama */}

                    {/*Apartado incidencias */}
                    <Grid item xs={6} id='scroll'>
                        <Card style={{ height: '600px', overflowY: 'auto' }}>
                            <CardContent sx={{ p: 2 }}>

                                <Grid containter spacing={2}>

                                    <Grid item xs={12} sx={{ pb: 2 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <Typography variant="h6">Incidéncias</Typography>
                                            <h4 style={{ marginLeft: '10px', fontSize: '1.25rem' }} >{elementoActivo.nombre}</h4>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12}>
                                        {
                                            elementoActivo.nombre ? (
                                                <Grid item xs={12}>
                                                    {/* <TableContainer component={Paper}>
                                                        <Table sx={{ minWidth: 650 }}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell width="50px;"></TableCell>
                                                                    <TableCell onClick={() => manejarOrdenColumnaIncidencia('fecha')} align="left" width="100px;">
                                                                        Fecha
                                                                        {ordenColumnaIncidencias === 'fecha' && (ordenAscendenteIncidencia ? ' ▲' : ' ▼')}
                                                                    </TableCell>
                                                                    <TableCell onClick={() => manejarOrdenColumnaIncidencia('fechaRealizado')} align="left" width="130px;">
                                                                        Realizado
                                                                        {ordenColumnaIncidencias === 'fechaRealizado' && (ordenAscendenteIncidencia ? ' ▲' : ' ▼')}
                                                                    </TableCell>
                                                                    <TableCell>Operario</TableCell>
                                                                    <TableCell>Incidéncia</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {

                                                                    elementoActivo.nombre &&
                                                                    // parametrosAnalisisFiltrados.map(row => {
                                                                    // parametrosIncidencias.map(row => {
                                                                    datosOrdenadosIncdencias.map(row => {
                                                                        return (
                                                                            <TableRow>
                                                                                <TableCell>
                                                                                    <IconButton className='' onClick={() => alert(row.observaciones)}>
                                                                                        <ErrorIcon />
                                                                                    </IconButton>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {row.fecha !== null ? new Date(row.fecha).toLocaleDateString() : ""}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {row.fechaRealizado !== null ? new Date(row.fechaRealizado).toLocaleDateString() : ""}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {buscarNombreOperario(row.operario)}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {row.observaciones}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    }
                                                                    )

                                                                }
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer> */}
                                                    <ObservacionesElementos
                                                        idElemento={elementoActivo.id}
                                                        observaciones={observaciones}
                                                        setObservaciones={setObservaciones}
                                                        observacion={observacion}
                                                        setObservacion={setObservacion}
                                                        observacionEditar={observacionEditar}
                                                        setObservacionEditar={setObservacionEditar}
                                                    >
                                                    </ObservacionesElementos>
                                                    <ComentariosElementosNoFQ
                                                        idElemento={elementoActivo.id}>

                                                    </ComentariosElementosNoFQ>
                                                </Grid>
                                            )
                                                :
                                                elementosGeneral.length > 0 ? (
                                                    <Grid item xs={12}>
                                                        <Tabs
                                                            value={tabActivoElementosGeneral}
                                                            onChange={handleTabElementosGeneralChange}
                                                            variant="scrollable"
                                                            scrollButtons="auto">
                                                            {
                                                                elementosGeneral.map((elemento, index) => {
                                                                    const nombre = elemento.nombre;
                                                                    const apellido = elemento.descripcion ? elemento.descripcion : elemento.numero
                                                                    return (
                                                                        <Tab value={index}
                                                                            label={nombre + ' ' + apellido}
                                                                            sx={{ fontSize: '1.20 rem' }}
                                                                            onClick={() => handleTabElementoGeneralClick(elemento)} />
                                                                    )
                                                                })
                                                            }
                                                        </Tabs>
                                                        {
                                                            elementosGeneral.map((elemento, index) => {
                                                                return (
                                                                    <TabPanel value={tabActivoElementosGeneral}
                                                                        index={index}>
                                                                        {/* <TableContainer component={Paper}>
                                                                            <Table sx={{ minWidth: 650 }}>
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <TableCell width="50px;"></TableCell>
                                                                                        <TableCell onClick={() => manejarOrdenColumnaIncidencia('fecha')} align="left" width="100px;">
                                                                                            Fecha
                                                                                            {ordenColumnaIncidencias === 'fecha' && (ordenAscendenteIncidencia ? ' ▲' : ' ▼')}
                                                                                        </TableCell>
                                                                                        <TableCell onClick={() => manejarOrdenColumnaIncidencia('fechaRealizado')} align="left" width="130px;">
                                                                                            Realizado
                                                                                            {ordenColumnaIncidencias === 'fechaRealizado' && (ordenAscendenteIncidencia ? ' ▲' : ' ▼')}
                                                                                        </TableCell>
                                                                                        <TableCell>Operario</TableCell>
                                                                                        <TableCell>Incidéncia</TableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                    {
                                                                                        incidenciasElementoGeneralSeleccionado.length > 0 ? incidenciasElementoGeneralSeleccionado.map(row => {
                                                                                            return (
                                                                                                <TableRow>
                                                                                                    <TableCell>
                                                                                                        <IconButton className='' onClick={() => alert(row.observaciones)}>
                                                                                                            <ErrorIcon />
                                                                                                        </IconButton>
                                                                                                    </TableCell>
                                                                                                    <TableCell>
                                                                                                        {row.fecha !== null ? new Date(row.fecha).toLocaleDateString() : ""}
                                                                                                    </TableCell>
                                                                                                    <TableCell>
                                                                                                        {row.fechaRealizado !== null ? new Date(row.fechaRealizado).toLocaleDateString() : ""}
                                                                                                    </TableCell>
                                                                                                    <TableCell>
                                                                                                        {buscarNombreOperario(row.operario)}
                                                                                                    </TableCell>
                                                                                                    <TableCell>
                                                                                                        {row.observaciones}
                                                                                                    </TableCell>
                                                                                                </TableRow>
                                                                                            )
                                                                                        }
                                                                                        )
                                                                                            :
                                                                                            <></>
                                                                                    }
                                                                                </TableBody>
                                                                            </Table>
                                                                        </TableContainer> */}
                                                                        <ObservacionesElementos
                                                                            idElemento={elemento.id}
                                                                            observaciones={observaciones}
                                                                            setObservaciones={setObservaciones}
                                                                            observacion={observacion}
                                                                            setObservacion={setObservacion}
                                                                            observacionEditar={observacionEditar}
                                                                            setObservacionEditar={setObservacionEditar}
                                                                        >
                                                                        </ObservacionesElementos>
                                                                        <ComentariosElementosNoFQ
                                                                            idElemento={elemento.id}>
                                                                        </ComentariosElementosNoFQ>
                                                                    </TabPanel>
                                                                )
                                                            })
                                                        }
                                                    </Grid>
                                                )
                                                    : <></>
                                        }
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* APARTADO CALENDARIO DE TAREAS POR ELEMENTO */}
                    <Grid item xs={6}>
                        <Card style={{ height: '600px', overflowY: 'auto' }}>
                            <CardContent>

                                <Grid container spacing={3} sx={{ mb: 5, justifyContent: 'space-between' }}>
                                    {
                                        elementoActivo.nombre ? (
                                            <>
                                                <Grid item>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <Typography variant="h6">Calendario de tareas</Typography>
                                                        <h4 style={{ marginLeft: '10px', fontSize: '1.25rem' }} >{elementoActivo.nombre}</h4>
                                                    </div>
                                                    <IconButton onClick={handleDecrementarContador}>
                                                        <NavigateBeforeIcon />
                                                    </IconButton>

                                                    <span>{contadorYear}</span>

                                                    <IconButton onClick={handleIncrementarContador}>
                                                        <NavigateNextIcon />
                                                    </IconButton>
                                                </Grid>
                                            </>
                                        ) : (
                                            <Grid item>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <Typography variant="h6">Calendario de tareas</Typography>
                                                    <h4 style={{ marginLeft: '10px', fontSize: '1.25rem' }} >{elementoActivo.nombre}</h4>
                                                </div>
                                                <IconButton onClick={handleDecrementarContador}>
                                                    <NavigateBeforeIcon />
                                                </IconButton>

                                                <span>{contadorYear}</span>

                                                <IconButton onClick={handleIncrementarContador}>
                                                    <NavigateNextIcon />
                                                </IconButton>
                                            </Grid>
                                        )
                                    }
                                </Grid>

                                <Grid item xs={12}>
                                    {
                                        elementoActivo.nombre ?
                                            (
                                                <TableContainer component={Paper}>
                                                    <Table sx={{ minWidth: 650 }}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell></TableCell>
                                                                <TableCell align="left">Tipo de análisis</TableCell>
                                                                <TableCell>Ene</TableCell>
                                                                <TableCell>Feb</TableCell>
                                                                <TableCell>Mar</TableCell>
                                                                <TableCell>Abr</TableCell>
                                                                <TableCell>May</TableCell>
                                                                <TableCell>Jun</TableCell>
                                                                <TableCell>Jul</TableCell>
                                                                <TableCell>Ago</TableCell>
                                                                <TableCell>Sep</TableCell>
                                                                <TableCell>Oct</TableCell>
                                                                <TableCell>Nov</TableCell>
                                                                <TableCell>Dic</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                elementoActivo.nombre &&
                                                                // Mapeamos todos los parametros
                                                                analisis.map(row => {

                                                                    // row -> id, nombre
                                                                    // tareasFiltradas -> analisis, elemento
                                                                    var currentTime = new Date();

                                                                    // Obtenemos todos los valores del parametro actual (valores del mismo parametro, enero, febrero, ...)
                                                                    const valoresPorTarea = parametrosFiltrados.filter(analisis => parseInt(analisis.analisis, 10) === row.id);

                                                                    let fechas = [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1]; // -1 = no existe registro, 0 = existe, pero no realizado, 1 = existe y realizado
                                                                    let valorFechas = [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1];
                                                                    let valorFechasRealizado = [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1];
                                                                    if (valoresPorTarea.length > 0) {

                                                                        // Mapeamos los valores en un array, y los registro que no estén seteamos una raya
                                                                        valoresPorTarea.map(val => {
                                                                            // Convertimos la fecha del registro en un objeto de fecha
                                                                            const fecha = new Date(val.fecha);
                                                                            // Contamos solo si los registros son de este año
                                                                            //if (fecha.getFullYear() === currentTime.getFullYear()) {
                                                                            if (fecha.getFullYear() === contadorYear) {
                                                                                for (let i = 0; i < 12; i++) {
                                                                                    if (fecha.getMonth() === i) {
                                                                                        valorFechas[i] = fecha;
                                                                                        if (val.incorrecto || val.noValido) {
                                                                                            fechas[i] = 2
                                                                                            valorFechasRealizado[i] = new Date(val.fechaRealizado);
                                                                                        } else {
                                                                                            if (val.realizado) {
                                                                                                fechas[i] = 1
                                                                                                valorFechasRealizado[i] = new Date(val.fechaRealizado);
                                                                                            } else {
                                                                                                fechas[i] = 0
                                                                                            }
                                                                                        }
                                                                                        // val.realizado
                                                                                        //     ? fechas[i] = 1
                                                                                        //     : fechas[i] = 0
                                                                                    }
                                                                                }
                                                                            }

                                                                        });

                                                                    }

                                                                    // Devolvemos los valores
                                                                    return (
                                                                        valoresPorTarea.length > 0 && (
                                                                            <TableRow
                                                                                key={row.id}
                                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                            >
                                                                                <TableCell>
                                                                                    <Tooltip title="Ver parametros del elemento" placement="right">
                                                                                        <IconButton onClick={() => handleSeleccionarAnalisis(row.id)}>
                                                                                            <TimelineIcon />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </TableCell>
                                                                                <TableCell aligh="left" component="th" scope="row">
                                                                                    {row.nombre}
                                                                                </TableCell>
                                                                                {
                                                                                    fechas.map((fecha, index) => (
                                                                                        <TableCell key={index}>
                                                                                            <IconButton
                                                                                                onClick={() => { descargarPdf(row.id, valorFechas[index]) }}
                                                                                                color={fecha === -1 ? 'primary' : fecha === 0 ? 'error' : fecha === 2 ? 'warning' : 'success'}
                                                                                                disabled={fecha === -1 ? true : false}
                                                                                            >
                                                                                                {
                                                                                                    fecha === -1
                                                                                                        ? <RemoveIcon />
                                                                                                        : fecha === 0
                                                                                                            ? <ClearIcon />
                                                                                                            : fecha === 2
                                                                                                                ? <WarningIcon />
                                                                                                                : <CheckIcon />
                                                                                                }
                                                                                            </IconButton>
                                                                                            {
                                                                                                fecha === -1
                                                                                                    ? <p></p>
                                                                                                    : valorFechasRealizado[index] === -1
                                                                                                        ? <p>{valorFechas[index] != -1 ? valorFechas[index].toLocaleDateString() : ""}</p>
                                                                                                        : <p>{valorFechasRealizado[index] != -1 ? valorFechasRealizado[index].toLocaleDateString() : ""}</p>
                                                                                            }
                                                                                            {/* <p>{valorFechas[index] != -1 ? valorFechas[index].toLocaleDateString() : ""}</p> */}
                                                                                        </TableCell>
                                                                                    ))
                                                                                }
                                                                            </TableRow>
                                                                        )
                                                                    )
                                                                })
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            )
                                            :
                                            (
                                                elementosGeneral.length > 0 ? (
                                                    <Grid item xs={12}>
                                                        <Tabs
                                                            value={tabActivoElementosGeneral}
                                                            onChange={handleTabElementosGeneralChange}
                                                            variant="scrollable"
                                                            scrollButtons="auto">
                                                            {
                                                                elementosGeneral.map((elemento, index) => {
                                                                    const nombre = elemento.nombre;
                                                                    const apellido = elemento.descripcion ? elemento.descripcion : elemento.numero
                                                                    return (
                                                                        <Tab value={index}
                                                                            label={nombre + ' ' + apellido}
                                                                            sx={{ fontSize: '1.20 rem' }}
                                                                            onClick={() => handleTabElementoGeneralClick(elemento)} />
                                                                    )
                                                                })
                                                            }
                                                        </Tabs>
                                                        {
                                                            elementosGeneral.map((elemento, index) => {
                                                                return (
                                                                    <TabPanel value={tabActivoElementosGeneral}
                                                                        index={index}>
                                                                        <TableContainer component={Paper}>
                                                                            <Table sx={{ minWidth: 650 }}>
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        <TableCell></TableCell>
                                                                                        <TableCell align="left">Tipo de análisis</TableCell>
                                                                                        <TableCell>Ene</TableCell>
                                                                                        <TableCell>Feb</TableCell>
                                                                                        <TableCell>Mar</TableCell>
                                                                                        <TableCell>Abr</TableCell>
                                                                                        <TableCell>May</TableCell>
                                                                                        <TableCell>Jun</TableCell>
                                                                                        <TableCell>Jul</TableCell>
                                                                                        <TableCell>Ago</TableCell>
                                                                                        <TableCell>Sep</TableCell>
                                                                                        <TableCell>Oct</TableCell>
                                                                                        <TableCell>Nov</TableCell>
                                                                                        <TableCell>Dic</TableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                    {
                                                                                        elementoGeneralSeleccionado &&
                                                                                        // Mapeamos todos los parametros
                                                                                        analisis.map(row => {

                                                                                            // row -> id, nombre
                                                                                            // tareasFiltradas -> analisis, elemento
                                                                                            var currentTime = new Date();

                                                                                            // Obtenemos todos los valores del parametro actual (valores del mismo parametro, enero, febrero, ...)
                                                                                            const valoresPorTarea = parametrosFiltrados.filter(analisis => parseInt(analisis.analisis, 10) === row.id);

                                                                                            let fechas = [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1]; // -1 = no existe registro, 0 = existe, pero no realizado, 1 = existe y realizado
                                                                                            let valorFechas = [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1];
                                                                                            let valorFechasRealizado = [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1];
                                                                                            if (valoresPorTarea.length > 0) {

                                                                                                // Mapeamos los valores en un array, y los registro que no estén seteamos una raya
                                                                                                valoresPorTarea.map(val => {
                                                                                                    // Convertimos la fecha del registro en un objeto de fecha
                                                                                                    const fecha = new Date(val.fecha);
                                                                                                    // Contamos solo si los registros son de este año
                                                                                                    //if (fecha.getFullYear() === currentTime.getFullYear()) {
                                                                                                    if (fecha.getFullYear() === contadorYear) {
                                                                                                        for (let i = 0; i < 12; i++) {
                                                                                                            if (fecha.getMonth() === i) {
                                                                                                                valorFechas[i] = fecha;
                                                                                                                if (val.incorrecto || val.noValido) {
                                                                                                                    fechas[i] = 2
                                                                                                                    valorFechasRealizado[i] = new Date(val.fechaRealizado);
                                                                                                                } else {
                                                                                                                    if (val.realizado) {
                                                                                                                        fechas[i] = 1
                                                                                                                        valorFechasRealizado[i] = new Date(val.fechaRealizado);
                                                                                                                    } else {
                                                                                                                        fechas[i] = 0
                                                                                                                    }
                                                                                                                }
                                                                                                                // val.realizado
                                                                                                                //     ? fechas[i] = 1
                                                                                                                //     : fechas[i] = 0
                                                                                                            }
                                                                                                        }
                                                                                                    }

                                                                                                });

                                                                                            }

                                                                                            // Devolvemos los valores
                                                                                            return (
                                                                                                valoresPorTarea.length > 0 && (
                                                                                                    <TableRow
                                                                                                        key={row.id}
                                                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                                                    >
                                                                                                        <TableCell>
                                                                                                            <Tooltip title="Ver parametros del elemento" placement="right">
                                                                                                                <IconButton onClick={() => handleSeleccionarAnalisis(row.id)}>
                                                                                                                    <TimelineIcon />
                                                                                                                </IconButton>
                                                                                                            </Tooltip>
                                                                                                        </TableCell>
                                                                                                        <TableCell aligh="left" component="th" scope="row">
                                                                                                            {row.nombre}
                                                                                                        </TableCell>
                                                                                                        {
                                                                                                            fechas.map((fecha, index) => (
                                                                                                                <TableCell key={index}>
                                                                                                                    <IconButton
                                                                                                                        onClick={() => { descargarPdf(row.id, valorFechas[index]) }}
                                                                                                                        color={fecha === -1 ? 'primary' : fecha === 0 ? 'error' : fecha === 2 ? 'warning' : 'success'}
                                                                                                                        disabled={fecha === -1 ? true : false}
                                                                                                                    >
                                                                                                                        {
                                                                                                                            fecha === -1
                                                                                                                                ? <RemoveIcon />
                                                                                                                                : fecha === 0
                                                                                                                                    ? <ClearIcon />
                                                                                                                                    : fecha === 2
                                                                                                                                        ? <WarningIcon />
                                                                                                                                        : <CheckIcon />
                                                                                                                        }
                                                                                                                    </IconButton>
                                                                                                                    {
                                                                                                                        fecha === -1
                                                                                                                            ? <p></p>
                                                                                                                            : valorFechasRealizado[index] === -1
                                                                                                                                ? <p>{valorFechas[index] != -1 ? valorFechas[index].toLocaleDateString() : ""}</p>
                                                                                                                                : <p>{valorFechasRealizado[index] != -1 ? valorFechasRealizado[index].toLocaleDateString() : ""}</p>
                                                                                                                    }
                                                                                                                    {/* <p>{valorFechas[index] != -1 ? valorFechas[index].toLocaleDateString() : ""}</p> */}
                                                                                                                </TableCell>
                                                                                                            ))
                                                                                                        }
                                                                                                    </TableRow>
                                                                                                )
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </TableBody>
                                                                            </Table>
                                                                        </TableContainer>
                                                                    </TabPanel>
                                                                )
                                                            })
                                                        }
                                                    </Grid>
                                                )
                                                    : <></>
                                            )
                                    }
                                </Grid>

                            </CardContent>
                        </Card>
                    </Grid>
                    {/* FIN APARTADO CALENDARIO DE TAREAS */}


                    <Grid container align="right">
                        <Grid item xs={12}>
                            <IconButton onClick={() => handleScrollToBottom()}>
                                <ArrowDownwardIcon fontSize="large" />
                            </IconButton>
                            <IconButton onClick={() => handleInicioPagina()}>
                                <ArrowUpwardIcon fontSize="large" />
                            </IconButton>
                        </Grid>
                    </Grid>


                    {/* APARTADO TABLA DE PARAMETROS */}
                    <Grid item xs={6}>
                        <Card style={{ height: '600px', overflowY: 'auto' }}>
                            <CardContent>

                                <Grid container spacing={3} sx={{ mb: 5, justifyContent: 'space-between' }}>
                                    {
                                        analisisActivo.nombre ? (
                                            <>
                                                <Grid item>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <Typography variant='h6'>Parámetros del Análisis</Typography>
                                                        {
                                                            elementoActivo.nombre ? (
                                                                <h4 style={{ marginLeft: '10px', fontSize: '1.25rem' }} >{elementoActivo.nombre}</h4>
                                                            )
                                                                :
                                                            (
                                                                elementoGeneralSeleccionado !== undefined && (
                                                                    <h4 style={{ marginLeft: '10px', fontSize: '1.25rem' }} >{elementoGeneralSeleccionado.descripcion !== null ? elementoGeneralSeleccionado.nombre + " " + elementoGeneralSeleccionado.descripcion : elementoGeneralSeleccionado.nombre + " " + elementoGeneralSeleccionado.numero}</h4>
                                                                )                                                            
                                                            )
                                                                
                                                        }
                                                    </div>
                                                    <IconButton onClick={handleDecrementarContador2}>
                                                        <NavigateBeforeIcon />
                                                    </IconButton>

                                                    <span>{contadorYear2}</span>

                                                    <IconButton onClick={handleIncrementarContador2}>
                                                        <NavigateNextIcon />
                                                    </IconButton>
                                                </Grid>
                                            </>
                                        ) : (
                                            <Grid item>
                                                <Typography variant='h6'>Selecciona un análisis del calendario</Typography>
                                                <IconButton onClick={handleDecrementarContador2}>
                                                    <NavigateBeforeIcon />
                                                </IconButton>

                                                <span>{contadorYear2}</span>

                                                <IconButton onClick={handleIncrementarContador2}>
                                                    <NavigateNextIcon />
                                                </IconButton>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                                {
                                    analisisActivo.nombre && (
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell></TableCell>
                                                        <TableCell align="left">Parámetro</TableCell>
                                                        <TableCell>Ene</TableCell>
                                                        <TableCell>Feb</TableCell>
                                                        <TableCell>Mar</TableCell>
                                                        <TableCell>Abr</TableCell>
                                                        <TableCell>May</TableCell>
                                                        <TableCell>Jun</TableCell>
                                                        <TableCell>Jul</TableCell>
                                                        <TableCell>Ago</TableCell>
                                                        <TableCell>Sep</TableCell>
                                                        <TableCell>Oct</TableCell>
                                                        <TableCell>Nov</TableCell>
                                                        <TableCell>Dic</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        analisisActivo.tipo === 0 ?
                                                            // Mapeamos todos los parametros
                                                            parametros.map(row => {

                                                                // Obtenemos todos los valores del parametro actual (valores del mismo parametro, enero, febrero, ...)
                                                                const valoresPorParametro = analisisParametros.filter(param => param.parametro === row.id);
                                                                let fechas = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

                                                                // Mapeamos los valores en un array, y si no hay datos seteamos un 0
                                                                valoresPorParametro.map(val => {

                                                                    const fecha = new Date(val.fecha);

                                                                    if (fecha.getFullYear() === contadorYear2) {
                                                                        for (let i = 0; i < 12; i++) {
                                                                            if (fecha.getMonth() === i) {
                                                                                if (val.valor && typeof val.valor === 'string' && val.valor.includes(',')) {
                                                                                    const nuevoValor = val.valor.replace(',', '.');
                                                                                    fechas[i] = nuevoValor;
                                                                                } else {
                                                                                    fechas[i] = val.valor;
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                });

                                                                // Devolvemos los valores
                                                                return (
                                                                    valoresPorParametro.length > 0 && (
                                                                        <TableRow
                                                                            key={row.id}
                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                        >
                                                                            <TableCell>
                                                                                <Tooltip title="Ver en la gráfica" placement="right">
                                                                                    {/* <IconButton onClick={() => handleSeleccionarParametro({ nombre: row.nombre, datos: fechas })}> */}
                                                                                    {/* Se modifica para que al hacer clic en boton cambie automaticamente de pestaña y pasamos los parametros para dibujar gráfico  */}
                                                                                    <IconButton onClick={() => handleTabClick(1, row.nombre, fechas)}>
                                                                                        <TimelineIcon />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </TableCell>
                                                                            <TableCell aligh="left" component="th" scope="row">
                                                                                {row.nombre}
                                                                            </TableCell>
                                                                            {
                                                                                fechas.map((fecha, index) => <TableCell key={index}>{fecha}</TableCell>)
                                                                            }
                                                                        </TableRow>
                                                                    )
                                                                )
                                                            })
                                                            :
                                                            (parametrosFiltradosNoFQ.length > 0 && (analisisActivo.nombre.includes('Legionela') || analisisActivo.nombre.includes('Aerobios'))) && (
                                                                (() => {
                                                                    const fechas = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

                                                                    // Organizar los resultados por mes
                                                                    parametrosFiltradosNoFQ.forEach(val => {
                                                                        const fecha = new Date(val.fecha);

                                                                        if (fecha.getFullYear() === contadorYear2) {
                                                                            fechas[fecha.getMonth()] = val.resultado;
                                                                        }
                                                                    });

                                                                    return (
                                                                        // Renderizar una fila con los resultados organizados
                                                                        <TableRow
                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                        >
                                                                            <TableCell>
                                                                                <Tooltip title="Ver en la gráfica" placement="right">
                                                                                    {/* <IconButton onClick={() => handleSeleccionarParametro({ nombre: row.nombre, datos: fechas })}> */}
                                                                                    {/* Se modifica para que al hacer clic en boton cambie automaticamente de pestaña y pasamos los parametros para dibujar gráfico  */}
                                                                                    <IconButton onClick={() => handleTabClick(1, analisisActivo.nombre, fechas)}>
                                                                                        <TimelineIcon />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </TableCell>
                                                                            <TableCell align="left" component="th" scope="row">
                                                                                {analisisActivo.nombre}
                                                                            </TableCell>
                                                                            {/* Mostrar los resultados de cada mes */}
                                                                            {fechas.map((fecha, idx) => (
                                                                                <TableCell key={idx}>{fecha != "" ? fecha : "0"}</TableCell>
                                                                            ))}
                                                                        </TableRow>
                                                                    )
                                                                })()
                                                            )
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    )
                                }

                            </CardContent>
                        </Card>
                    </Grid>

                    {/* APARTADO DOCUMENTOS */}
                    <Grid item xs={6}>
                        <Card style={{ height: '600px', overflowY: 'auto' }}>
                            <CardContent sx={{ p: 2 }}>
                                {
                                    elementoActivo.nombre ?
                                        (
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <h4 style={{ marginLeft: '10px', fontSize: '1.25rem' }} >{elementoActivo.nombre}</h4>
                                                    <Tabs
                                                        value={activeTab}
                                                        onChange={handleTabChange}
                                                        indicatorColor="primary"
                                                        textColor="primary"
                                                    /*centered*/
                                                    >
                                                        <Tab label="Documentos" />
                                                        <Tab label="Gráfico" />
                                                    </Tabs>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TabPanel value={activeTab} index={0}>
                                                        {/* Contingut del grid PDF'S */}
                                                        {/*Apartado PDF'S */}
                                                        <Grid item xs={12}>
                                                            <Card style={{ height: '600px', overflowY: 'auto' }}>
                                                                <CardContent sx={{ p: 2 }}>
                                                                    <DocumentosAgrupados idUsuario={user.idPerfil} planta={plantaActiva} cliente={clienteSeleccionado} elementoActivo={elementoActivo.nombre} />
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    </TabPanel>

                                                    <TabPanel value={activeTab} index={1}>
                                                        {/* Contingut del grid Gràfics */}
                                                        <Grid item xs={12}>
                                                            <Card style={{ height: '600px', overflowY: 'auto' }}>
                                                                <CardContent sx={{ p: 2 }}>

                                                                    <Grid container spacing={6} sx={{ mb: 2, justifyContent: 'space-between' }}>
                                                                        {
                                                                            parametroActivo.nombre ? (
                                                                                <>
                                                                                    <Grid item>
                                                                                        <Typography variant='h6'>Vista gráfica del parámetro</Typography>
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <Chip label={parametroActivo.nombre} color="primary" />
                                                                                    </Grid>
                                                                                </>
                                                                            ) : (
                                                                                <Grid item>
                                                                                    <Typography variant='h6'>Selecciona un parámetro de la tabla</Typography>
                                                                                </Grid>
                                                                            )
                                                                        }
                                                                    </Grid>

                                                                    <ChartContainer />

                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                    </TabPanel>
                                                </Grid>
                                            </Grid>
                                        )
                                        :
                                        (
                                            elementoGeneralSeleccionado !== undefined &&
                                            (

                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <h4 style={{ marginLeft: '10px', fontSize: '1.25rem' }} >{elementoGeneralSeleccionado.descripcion !== null ? elementoGeneralSeleccionado.nombre + " " + elementoGeneralSeleccionado.descripcion : elementoGeneralSeleccionado.nombre + " " + elementoGeneralSeleccionado.numero}</h4>
                                                        <Tabs
                                                            value={activeTab}
                                                            onChange={handleTabChange}
                                                            indicatorColor="primary"
                                                            textColor="primary"
                                                        /*centered*/
                                                        >
                                                            <Tab label="Documentos" />
                                                            <Tab label="Gráfico" />
                                                        </Tabs>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TabPanel value={activeTab} index={0}>
                                                            {/* Contingut del grid PDF'S */}
                                                            {/*Apartado PDF'S */}
                                                            <Grid item xs={12}>
                                                                <Card style={{ height: '600px', overflowY: 'auto' }}>
                                                                    <CardContent sx={{ p: 2 }}>
                                                                        <DocumentosAgrupados idUsuario={user.idPerfil} planta={plantaActiva} cliente={clienteSeleccionado} elementoActivo={elementoGeneralSeleccionado.descripcion !== null ? elementoGeneralSeleccionado.nombre + " " + elementoGeneralSeleccionado.descripcion : elementoGeneralSeleccionado.nombre + " " + elementoGeneralSeleccionado.numero} />
                                                                    </CardContent>
                                                                </Card>
                                                            </Grid>
                                                        </TabPanel>

                                                        <TabPanel value={activeTab} index={1}>
                                                            {/* Contingut del grid Gràfics */}
                                                            <Grid item xs={12}>
                                                                <Card style={{ height: '600px', overflowY: 'auto' }}>
                                                                    <CardContent sx={{ p: 2 }}>

                                                                        <Grid container spacing={6} sx={{ mb: 2, justifyContent: 'space-between' }}>
                                                                            {
                                                                                parametroActivo.nombre ? (
                                                                                    <>
                                                                                        <Grid item>
                                                                                            <Typography variant='h6'>Vista gráfica del parámetro</Typography>
                                                                                        </Grid>
                                                                                        <Grid item>
                                                                                            <Chip label={parametroActivo.nombre} color="primary" />
                                                                                        </Grid>
                                                                                    </>
                                                                                ) : (
                                                                                    <Grid item>
                                                                                        <Typography variant='h6'>Selecciona un parámetro de la tabla</Typography>
                                                                                    </Grid>
                                                                                )
                                                                            }
                                                                        </Grid>

                                                                        <ChartContainer />
                                                                    </CardContent>
                                                                </Card>
                                                            </Grid>
                                                        </TabPanel>
                                                    </Grid>
                                                </Grid>
                                            )
                                        )
                                }
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid container align="right">
                        <Grid item xs={12}>
                            <IconButton onClick={() => handleInicioPagina()}>
                                <ArrowUpwardIcon fontSize="large" />
                            </IconButton>
                        </Grid>
                    </Grid>

                </Grid>
            }

        </>
    );

}

export default HomeCliente;